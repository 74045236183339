/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-02",
    versionChannel: "nightly",
    buildDate: "2023-01-02T04:53:06.081Z",
    commitHash: "0f5c47beb2de1f5c68e1a7412d294c6e7da35258",
};
